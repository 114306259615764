<template>
    <div class="col-6 col-md-6 col-lg-3 mb-3">
        <div class="card main-card">
            <div class="card-body">
                <v-card class="mx-auto" max-width="400">
                    <discount :discount="value.rewardRate" :color="value.color" />
                    <v-img class="black--text align-end" max-height="200px" :src="value.thumbnail">
                    </v-img>
                    <v-card-text class="pb-0 card-title-htl fsize-2" :class="'price-' + value.color">
                        {{ (value.price / htlEur).toFixed(2) }} HTL
                    </v-card-text>
                </v-card>
                <v-dialog v-model="dialog" max-width="500px">
                    <v-card elevation="20">
                        <v-card-title class="headline grey lighten-2" primary-title>{{ $t('buy-package') }}</v-card-title>
                        <v-card-title class="headline">
                            {{ $t('buy-package-for-of', [(value.price / htlEur).toFixed(2), (available / 1e8).toFixed(2)])
                            }}
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn class="text-unset" small light flat @click="dialog = false">{{ $t('close') }}</v-btn>
                            <v-btn color="primary" class="text-unset" small dark @click="buy">
                                {{ $t('buy-package-for', [(value.price / htlEur).toFixed(2)]) }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
            <div class="card-footer">
                <v-btn color="primary" text elevation="24" block small @click="dialog = true"
                    v-if="available > value.price / htlEur * 1e8" :class="['btn-pack-' + value.color, 'text-unset']">
                    {{ $t('buy-package') }}
                </v-btn>
                <buy-lock v-else :available="available" :price="value.price" :color="value.color" :pack="value"
                    :deposit-address="depositAddress" :parent="null" />
            </div>
        </div>
    </div>
</template>
<script>
import Discount from "./Discount";
import BuyLock from "./BuyLock";
import { mapGetters } from "vuex";
import gql from "graphql-tag";
export default {
    name: "PackageBox",
    components: {
        Discount,
        BuyLock
    },
    props: ["value", "available", "rate", "depositAddress"],
    data() {
        return {
            rates: {
                htlEur: 0.95
            },
            dialog: false
        }
    },
    created() {
        console.log(this.value.thumbnail);
    },
    computed: {
        ...mapGetters({
            htlEur: 'prices/htlEur',
            eurUsd: 'prices/eurUsd',
            token: 'prices/rate'
        })
    },
    methods: {
        buy() {
            this.$apollo.mutate({
                mutation: gql`mutation($id:ID!,$token:String!,$eurUsd:Float){buyPackage(type:$id,token:$token,eurUsd:$eurUsd)}`,
                variables: {
                    id: this.value.id,
                    token: this.token,
                    eurUsd: this.eurUsd
                },
            }).then(() => {
                this.$router.push("/packages")
            })
        }
    }
}
</script>
<style scoped>
.widget-chart {
    min-height: 220px;
    padding: 0;
}

.widget-chart-wrapper {
    left: auto;
    bottom: auto;
    opacity: 0.9;
}

.widget-bg-img {
    max-height: 170px;
    width: 100%;
    position: center;
    z-index: -1 !important;
}

.widget-price {
    padding-top: 120px;
    padding-left: 20px;
    position: left;
}

.widget-btns {
    padding-top: 170px;
}

.btn-pack-primary {
    background-color: #56aaff !important;
}

.btn-pack-secondary {
    background-color: #8bca63 !important;
}

.btn-pack-tertiary {
    background-color: #D4AF37 !important;
}

.price-primary {
    color: #56aaff;
}

.price-secondary {
    color: #8bca63;
}

.price-tertiary {
    color: #D4AF37;
}

.card-title-htl {
    font-weight: 600;
    font-size: 2rem;
    padding-left: 15px;
}

@media (max-width: 420px) {
    .card-title-htl {
        font-size: 1rem;
        padding-left: 5px;
    }

    .card-footer {
        padding: 0.1rem 5px;
    }
}

.card-body {
    padding: 0.6rem;
}
</style>
