<template>
    <div v-if="page">
        <h2>{{$t('buy-title')}}</h2>
        <div v-if="!!page.doc.content" class="row mb-3">
            <page-title :subheading="page.doc.content"></page-title>
        </div>
        <div class="row justify-content-center mb-3" v-if="!!page.lockedRate && !!page.viewer.depositAddress">
            <upgrade-info v-if="!!page.lockedRate.upgrade" :locked-rate="page.lockedRate" :address="page.viewer.depositAddress" :packages="page.packages" />
            <locked-rate-info v-else :locked-rate="page.lockedRate" :address="page.viewer.depositAddress" :packages="page.packages" />

        </div>
        <div class="row justify-content-center mb-3" v-else-if="page">
            <package-box v-for="pkg in page.packages" :key="pkg.price" :value="pkg" :available="available" :token="token" :rate="rate" :deposit-address="page.viewer.depositAddress" />
        </div>
        <div v-else>
            <div class="font-icon-wrapper float-left mr-3 mb-3">
                <div class="loader-wrapper d-flex justify-content-center align-items-center">
                    <pacman-loader color="primary"></pacman-loader>
                </div>
                <p></p>
            </div>
        </div>
    </div>

</template>

<script>
    import PackageBox from "../components/Packages/PackageBox";
    import LockedRateInfo from "../components/Packages/LockedRateInfo";
    import gql from "graphql-tag";
    import PacmanLoader from "vue-spinner/src/PacmanLoader.vue";
    import {mapGetters} from "vuex";
    import UpgradeInfo from "@/components/Packages/UpgradeInfo";
    import PageTitle from "@/components/common/PageTitle";
    export default {
        name: "BuyPackagePage",
        components: {
            UpgradeInfo,
            PackageBox,
            LockedRateInfo,
            PageTitle,
            PacmanLoader
        },
        data() {
            return {
                page: {
                    doc: {
                        content: null
                    },
                    lockedRate: null,
                    viewer: null,
                    packages: null
                }
            }
        },
        computed: {
            packet() {
                return this.$store.state.packages.lockedPacket;
            },
            ...mapGetters({
                available: 'user/available',
                token: 'prices/rate',
                rate: 'prices/htlEur'
            })
        },
        apollo: {
            page: {
                query: gql`query($lang:String){doc(id:"buy-packages"){content,title},
packageCategories(lang:$lang){id,name,packages{id,name,price,rewardRate,rewards,thumbnail,color}},
lockedRate{expiresAt,rate,rateCrypto,token,pack,currency,htlRate,upgrade,price},viewer{id,depositAddress{id,address,currency,expiresAt,lockedRate},totals{tokens}},viewerIsAdmin}`,
                variables: {
                  lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
                },
                update: data => {
                    data.packages = data.packageCategories.flatMap(p => p.packages)
                    console.log("data")
                    console.log(data)
                    console.log("data.packages")
                    console.log(data.packages)

                    for(var ii=0;ii<data.packages.length;ii++) {
                        console.log("data.packages[ii].id")
                        console.log(data.packages[ii].id)
                        console.log("data.packages[ii].name")
                        console.log(data.packages[ii].name)                        
                        console.log("data.packages[ii].price")
                        console.log(data.packages[ii].price)                        
                    }

                    return data
                },
                result({data}) {
                    if (data) {
                        this.$store.commit('user/updateAvailable', {
                            tokens: data.viewer.totals.tokens
                        })
                        if (data.viewerIsAdmin) {
                            this.$store.commit('user/setAdmin')
                        }
                    }
                }

            }
        }
    }
</script>

<style scoped>

</style>