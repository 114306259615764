<template>
  <div class="info-box-corner" :class="'info-box-corner-' + color">
    <div>{{ discount }} %</div>
  </div>
</template>
<script>
export default {
  name: "Discount",
  props: ["discount", "color"]
}
</script>
<style scoped>
.info-box-corner {
  position: absolute;
  right: 0;
  top: 0;
  width: 110px;
  height: 30px;
  font-size: 18px;
  overflow: hidden;
  z-index: 900;
  color: #FFFFFF;
}

.info-box-corner-primary:before {
  position: absolute;
  right: 0;
  content: "";
  border-style: solid;
  border-width: 0 110px 110px 0;
  border-color: transparent #56aaff transparent transparent;
  z-index: 900;
}

.info-box-corner-tertiary:before {
  position: absolute;
  right: 0;
  content: "";
  border-style: solid;
  border-width: 0 110px 110px 0;
  border-color: transparent #D4AF37 transparent transparent;
  z-index: 900;
}

.info-box-corner-secondary:before {
  position: absolute;
  right: 0;
  content: "";
  border-style: solid;
  border-width: 0 110px 110px 0;
  border-color: transparent #8bca63 transparent transparent;
  z-index: 900;
}

.info-box-corner div {
  position: relative;
  width: 110px;
  margin-left: 10px;
  margin-right: 5px;
  text-align: center;
  z-index: 900;
}

@media (max-width: 576px) {
  .info-box-corner {
    position: absolute;
    right: 0;
    top: 0;
    width: 90px;
    height: 20px;
    color: #fff;
    font-size: 14px;
    overflow: hidden;
    z-index: 900;
  }

  .info-box-corner-primary:before {
    position: absolute;
    right: 0;
    content: "";
    border-style: solid;
    border-width: 0 90px 90px 0;
    border-color: transparent #56aaff transparent transparent;
    z-index: 900;
  }

  .info-box-corner-secondary:before {
    position: absolute;
    right: 0;
    content: "";
    border-style: solid;
    border-width: 0 90px 90px 0;
    border-color: transparent #8bca63 transparent transparent;
    z-index: 900;
  }

  .info-box-corner div {
    position: relative;
    width: 90px;
    text-align: center;
    z-index: 900;
    margin-left: 10px;
  }
}
</style>